import GruaPickersUtilsProvider from "@grua/core/GruaPickersUtilsProvider";
import { GruaI18NextProvider } from "@grua/core/I18n";
import "@grua/core/prototypes";
import ThemeProvider from "@grua/core/styles/ThemeProvider";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarContextProvider from "_common/contexts/snackbarContext";
import App from "./App";

require("@grua/core/prototypes");

ReactDOM.render(
  <ThemeProvider>
    <GruaPickersUtilsProvider>
      <GruaI18NextProvider>
        <SnackbarContextProvider>
          <Router>
            <App />
          </Router>
        </SnackbarContextProvider>
      </GruaI18NextProvider>
    </GruaPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
