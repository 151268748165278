import { useContext } from "react";
import { SnackbarContext } from "_common/contexts/snackbarContext";
import CommonSnackbarView from "./CommonSnackbarView";

const CommonSnackbar = () => {
  const { snackbarConfig, onClose } = useContext(SnackbarContext);

  const handleClose = (_: any, reason?: "clickaway") => {
    if (reason === "clickaway") return;

    onClose();
  };

  return (snackbarConfig.open && <CommonSnackbarView {...{ snackbarConfig, handleClose }} />) || null;
};

export default CommonSnackbar;
