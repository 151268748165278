import { memo } from "react";
import { Route, Switch } from "react-router-dom";
import FallbackRoute from "./fallback-route";

interface FallbackSwitchProps {
  children: React.ReactNode;
  fallbackComponent?: React.ComponentType<any>;
  fallbackPathname?: string;
}

const FallbackSwitch = ({
  children,
  fallbackPathname,
  fallbackComponent = () => <FallbackRoute fallbackPathname={fallbackPathname} />,
}: FallbackSwitchProps) => (
  <Switch>
    {children}

    <Route path="*" component={fallbackComponent} />
  </Switch>
);

export default memo(FallbackSwitch);
