import { Link } from "react-router-dom";
import Paper from "@grua/core/Paper/Paper";
import Grid from "@grua/core/Grid/Grid";
import Typography from "@grua/core/Typography/Typography";

interface FallbackRouteProps {
  fallbackPathname?: string;
}

const FallbackRoute = ({ fallbackPathname = "/" }: FallbackRouteProps) => (
  <Grid container alignItems="center" justifyContent="center" height="100%">
    <Grid item>
      <Paper>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Ops! Página não encontrada.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" align="center" color="textSecondary">
              <strong>404</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" gutterBottom>
              O endereço que você está tentando acessar não foi encontrado.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Link to={fallbackPathname} data-testid="grua-fallback-btn-home">
              <Typography align="center" color="primary">
                Voltar para o início
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

export default FallbackRoute;
