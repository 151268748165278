import Snackbar from "@grua/core/Snackbar";
import { memo } from "react";

interface CommonSnackbarViewProps {
  snackbarConfig: {
    open: boolean;
    message: string;
    variant?: "success" | "error" | "warning" | "info";
    options: any;
  };
  handleClose: (event: Event, reason: "clickaway") => void;
}

const CommonSnackbarView = ({ snackbarConfig, handleClose }: CommonSnackbarViewProps) => {
  const { open, message, variant, options } = snackbarConfig;
  return <Snackbar {...{ open, message, variant }} {...options} onClose={handleClose} />;
};

export default memo(CommonSnackbarView);
