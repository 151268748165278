import { useTranslation } from "@grua/core/I18n";
import CommonSnackbar from "_common/components/CommonSnackbar";
import enExampleModuleJson from "_common/I18n/locales/en/common.json";
import ptBrExampleModuleJson from "_common/I18n/locales/pt-BR/common.json";
import useStyles from "./AppStyle";
import MainRoutes from "./MainRoutes";

const App = () => {
  useStyles();

  const { i18n } = useTranslation();

  i18n.addResourceBundle("pt-BR", "common", ptBrExampleModuleJson);
  i18n.addResourceBundle("en", "common", enExampleModuleJson);

  i18n.changeLanguage("pt-BR");

  return (
    <>
      <MainRoutes />
      <CommonSnackbar />
    </>
  );
};

export default App;
