import makeStyles from "@grua/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  "@global": {
    "*": {
      boxSizing: "border-box",
    },
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
      margin: 0,
    },
    "#root": {
      height: "100%",
    },
    main: {
      overflowY: "scroll",
      WebkitOverflowScrolling: "touch",
    },
  },
}));

export default useStyles;
